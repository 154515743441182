.banner-home {
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  margin-top: 77px;

  @media (max-width: 1024px) {
    margin-top: 67px;
  }

  @media (max-width: 600px) {
    margin-top: 56px;
  }
}

// Banner slider styles

.banner-home--slider {
    position: relative;
    max-width: 100vw;
    max-height: 700px;
    overflow: hidden;
}

.banner-home--img {
    width: 100vw;
    max-height: 700px;
    object-fit: cover;
    object-position: center;
    animation-name: zoom;
    animation-duration: 10s;
    @media (max-width: 1024px) {
      min-height: 500px;
    }

    @media (max-width: 767px) {
      min-height: 480px;
    }

    @media (max-width: 600px) {
      min-height: 450px;
    }
}

@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

  .banner-home--fade {
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .banner-home--slide {
    display: none;
    max-width: 100vw;
    overflow: hidden;
}

// Styles for texts and button on banner
  .banner-home--contents {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    max-width: 1278px;
    width: 90%;
    margin: 0 auto;
  }

  .banner-home-text--container {
    max-width: 690px;
    width: 90%;
  }

  .banner-home--text, .banner-home--title {
    color: #fff;
    text-transform: uppercase;
    
  }

  .banner-home--btn {
    width: 180px;
    height: 55px;
    @media (max-width: 700px) {
      width: 140px;
      height: 40px;
   }
    
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 3px 15px #636262;
    background: rgb(67,138,239);
    background: linear-gradient(90deg, rgba(4,67,158,1) 0%,  rgba(67,138,239,1) 100%);
    font-size: 16px;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
    // transition-property: all;
    // transition-duration: 0.5s;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .banner-home--btn:hover {
    background: linear-gradient(90deg, rgba(67,138,239,1) 0%,   rgba(4,67,158,1) 100%);
    color: #fff;
    transition-property: all;
    transition-duration: 0.5s;
  }

  .banner-home--title {
    margin-top: 15px;
    // letter-spacing: 2px;
    @media (max-width: 400px) {
      font-size: 25px;
      // letter-spacing: 1px;
    }
  }

  .banner-home--text {
    font-size: 14px;
    letter-spacing: 2px;
  }

  // Styles for cards on banner

  .banner-home--cards {    
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .banner-home-cards--container {
    display: flex;
    justify-content: center;
    box-shadow: 0px  5px  10px #bbbbbb;
    max-width: 1278px;
    width: 90%;
    margin-top: -60px;
    margin-bottom: 40px;
    z-index: 3;
    overflow: hidden;
    @media (max-width: 767px) {
      flex-direction: column;
    }
   // background: black;
  }

  .banner-home--card {
    width: 34%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 30px 20px;
    background-color: #fff;
    @media (max-width: 767px) {
      width: auto;
      padding: 20px 15px;
      flex-direction: row;
      // align-items: center;
      justify-content: flex-start;
    }
  }

  .banner-home-card--img {
    width: 46px;
    height: 46px;
    object-fit: contain;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding-right: 20px;
      margin: 0;
    }
    @media (max-width: 600px) {
      padding-right: 10px;
    }
  }

  .banner-home-card--title {
    color: #ff9f36;
    margin-bottom: 10px;
    @media (max-width: 400px) {
      font-size: 16px;
    }
  }

   .banner-home--card:last-child {
    background-color: #04439e;
    .banner-home-card--text {
      color: #fff;
    }
   }

   .banner-home-card--text {
    font-weight: 300;
    font-size: 16px;
    @media (max-width: 400px) {
      font-size: 14px;
    }
   }