
body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Noto Sans SC', sans-serif;
 
}

#chinese-language  {
  font-family: 'Noto Sans SC', sans-serif;
}

#french-language {
   font-family: 'Montserrat', sans-serif !important;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
}

a{
  text-decoration: none;
}

h1{
  font-size: 5rem;
  
    font-weight: 900;
}
h2{
  font-size: 4rem;
  color: #222;
  font-weight: 600;
  /* line-height: 50px; */
}
h3{
  font-size: 2.4rem;
  font-weight: 500;
}
h4{
  font-size: 1.4rem;
}
p{
  font-size: 1.8rem;
  color: #555;
}
@media(max-width:  1024px){
  h1{
    font-size: 4rem;
  }
  h2{
    font-size: 3rem;
  }
  h3{
    font-size: 2.2rem;
  }
 P{
  font-size: 1.6rem;
 } 
}
@media(max-width:  600px){
  h1{
   font-size: 2.8rem;
  } 
  h2{
    font-size: 2.4rem;
  }
  h3{
    font-size: 1.8rem;
  }

 }