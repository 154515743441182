.product-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 210px;
    @media (max-width: 1024px) {
        margin-top: 70px;
        margin-bottom: 120px;
    }
    @media (max-width: 480px) {
        margin-top: 50px;
        margin-bottom: 100px;
    }

}

.product-main-top--content {
    max-width: 1280px;
    width: 90%;
    margin-bottom: 60px;
}

.product-main-top{
    display: flex;
    align-items: center;
}

.product-main-top--title2 {
    color: #222;
    margin-top: 10px;
}

.product-main-top--text {
    color: #777;
    margin-top: 20px;
}
.product-main-top--title1 {
    color: #ff9f36;
}

.product-main-top--line {
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}
.product-main-card--container {
    display: flex;
    max-width: 1280px;
    width: 90%;
    column-gap: 5%;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
    }
}

.product-main-card {
    max-width: 400px;
    width: 32%;
    @media (max-width: 767px) {
        max-width: 450px;
        width: 100%;
    }
}

.product-main-card--img {
    width: 100%;
    object-fit: cover;
}

.product-main-card--content {
    margin-top: 20px;
}

.product-main-card--title {
    color: #083E89;
}

.product-main-card--text {
    margin-top: 15px;
    //color: #777;
    margin-bottom: 10px;
}

.product-main-card--text--list {
    display: flex;
    margin-bottom: 2px;
    // justify-content: space-between;
    // border-bottom: 1px solid #ddd;
}

.product-main-card--text--list:last-child {
    border: 0;
}

.product-main-card-text-list--title {
    font-weight: 700;
    width: 80px;
    // padding-left: 10px;
}

.product-main-card-text-list--value {
    width: 110px;
    // border-left: 1px solid #ddd;
    padding-left: 10px;
}


/************** Product Specification Download section****************/

.prod-spec-container{
   // border: 1px solid #083E89;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.prod-spec-container:hover {
 color: #ff9f36;
}
.prod-spec{
    margin-top: 10px;
    margin-bottom: 10px;
    //padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.prod-spec:hover h4 {
    color: #ff9f36;
}
.prod-spec h4{
    color: #083E89;
    font-weight: 500;
}
.prod-spec-line{
    width: 100%;
    height: 1px;
    border: none;
    opacity: 1;
    background-color: #083E89;
    margin-top: 5px;
}
hr:hover{
    background-color: #ff9f36;
}