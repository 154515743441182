.our-history-header {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}
.company-history {
  max-width: 1000px;
  margin: 120px auto;
  padding: 0 50px;
  position: relative;
}

/************ Center Line ************/

.company-history .center-line {
  position: absolute;
  height: 100%;
  border: 1px dashed #f77b00;
  left: 50%;
  top: -28px;
  transform: translateX(-50%);
}
.company-history .row {
  display: flex;
}
/**** Card Content****/

.year {
  font-size: 24px;
  color: #f77b00;
  font-weight: bold;
}
.title {
  // color: #fff;
  padding-top: 10px;
}
.history-text {
  color: #d8d8d8;
  padding-top: 10px;
}
.company-history .row-1 {
  justify-content: flex-start;
  padding-bottom: 50px;
}
.company-history .row-2 {
  justify-content: flex-end;
  padding-bottom: 50px;
}

.company-history .row section {
  background: #f3f3f5;
  border-radius: 5px;
  width: calc(50% - 40px);
  position: relative;
}
.history-detail {
  padding: 30px 20px;
}
.company-history .row section:hover {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(215, 98, 8, 1) 0%,
    rgba(247, 123, 0, 1) 100%
  );
  transition: 0.8s;
}
.company-history .row section:hover .year {
  color: white;
}
.company-history .row section:hover .title {
  color: #fff;
}
.company-history .row section:hover .history-text p {
  color: #e1e1e1;
}
// width: 0%;
// background: linear-gradient(to right, #F02830 6%, #FF5E14 96%);
// transition: .5s;
/*** The indicator icon / Triangel ****/

.company-history .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  background: #f3f3f5;
  width: 25px;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.company-history .row section::before:hover {
  background: linear-gradient(
    90deg,
    rgba(247, 123, 0, 1) 0%,
    rgba(247, 123, 0, 1) 50%,
    rgba(255, 188, 123, 1) 100%
  );
}
.row-1 section::before {
  right: -7px;
}
.row-2 section::before {
  left: -7px;
}

/************* Timeline icon ************/
.row section .time-line-icon {
  position: absolute;
  height: 15px;
  width: 30px;
  top: 28px;
  //background: #f2f2f2;
  //text-align: center;
  //line-height: 40px;
  border-radius: 50%;
  //box-shadow: 0 0 0 3px #fff, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05) ;
}

.row-1 section .time-line-icon {
  right: -55px;
}
.row-2 section .time-line-icon {
  left: -55px;
}
.row-2 section .time-line-icon-left {
  top: 20px;
}

/******************** Arrow-Icon ******************/
.arrow-icon-up {
  position: absolute;
  right: -61px;
  bottom: -68px;
  color: #f77b00;
}
.arrow-icon-down {
  position: absolute;
  right: -61px;
  top: -70px;
}

/*************** Responsiveness ****************/
@media (max-width: 750px) {
  .company-history .center-line {
    left: 40px;
  }
  .company-history .row {
    margin: 30px 0 3px 30px;
  }
  .company-history .row section {
    width: 100%;
  }
  .row-1 section::before {
    left: -7px;
  }
  .row-1 section .time-line-icon {
    left: -55px;
  }
  .row-2 section .time-line-icon {
    left: -55px;
  }
  .arrow-icon-down,
  .arrow-icon-up {
    left: -60px;
  }
}
@media (max-width: 450px) {
  .company-history .center-line,
  .row-1 section::before,
  .row-2 section::before,
  .row-1 section .time-line-icon,
  .row-2 section .time-line-icon,
  .arrow-icon-down,
  .arrow-icon-up {
    display: none;
  }
  .company-history {
    padding: 0 30px;
    margin: 0 auto;
  }
  .company-history .row {
    margin: 0;
  }
  .company-history .row-1,
  .company-history .row-2 {
    padding-bottom: 30px;
  }
}
