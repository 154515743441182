.banner-common {
    width: 100%;
    height: 370px;
    @media (max-width: 600px) {
      height: 270px;
    }
    position: relative;
    //background-image: url(../../assets/AboutBanner.png);
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: 77px;

    @media (max-width: 1024px) {
      margin-top: 67px;
    }
  
    @media (max-width: 600px) {
      margin-top: 56px;
    }

  
  }
  .banner-overlay{
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.95) 5%,  rgba(0,0,0,1) 15%, rgba(0,0,0,0.9) 25%, rgba(2, 2, 2,0) 40%, rgb(3, 3, 3,0) 100%);
  }

  .banner-common .banner-title {
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0;
    text-align: start;
    z-index: 10;
    // margin-left: 10%;
    font-weight: bold;
    color: white;
    z-index: 1;
 
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .banner-title-span {
    max-width: 1280px;
    width: 90%;
    align-self: center;
    text-transform: uppercase;
  }