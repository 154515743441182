.product-app {
    width: 100%;
    // padding-top: 5%;
    // padding-bottom: 10%;
    background-color: #f3f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 90px;
    padding-bottom: 100px;
    @media (max-width: 1024px) {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    @media (max-width: 480px) {
        padding-top: 50px;
        padding-bottom: 60px;
    }
}

.product-app--content {
    max-width: 1280px;
    width: 90%;
}

.product-app-top {
    display: flex;
    align-items: center;
}

.product-app-top--title1 {
    color: #ff9f36;
}

.product-app-top--line {
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}

.product-app-top--title2 {
    margin-top: 10px;
}

.product-app-cards {
    max-width: 1280px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.product-app-card {
    max-width: 400px;
    width: 32%;
    min-height: 400px;
    

    @media (max-width: 1024px) {
        min-height: 400px;
    }
    background-color: #fff;
    box-shadow: 0px 6px 15px #dadee3;
    border-radius: 8px;
    @media (max-width: 767px) {
        max-width: 450px;
        width: 100%;
        margin-bottom: 5%;
    }
}

.product-app-card-img {
    width: 100%;
    min-height: 190px;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.product-app-card-content {
    padding: 20px;
}

.product-app-card-title {
    color: #222;
}

.product-app-card-text {
    //color: #777;
    margin-top: 20px;
}