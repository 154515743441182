.core-values-main {
  display: flex;
}
.core-values-container {
  background-image: url(../../assets/CoreValuesBg.jpg);
  margin: 50px 0;
}
.core-values-content {
  padding: 120px 0;
  @media (max-width: 500px) {
    padding: 70px 0;
  }
}
.core-values-main {
  display: flex;
  justify-content: center;
  //align-items: center;
  // background-image: url(/src/assets/NetworkedFotsBg.png);
  gap: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.core-values-intro {
  width: 80%;
  @media (max-width: 1300px) {
    width: 90%;
  }
  margin: auto;
}
.core-values-title {
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.core-values-title h2 {
  color: #fff;
}
.core-values-text {
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.core-values-text p {
  color: #e5e5e5;
}

.core-values-list {
  padding-top: 70px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 80%;
  margin: auto;
  gap: 60px;
  @media (max-width: 1300px) {
    width: 90%;
    gap: 60px;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    padding-top: 90px;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 60px;
    padding-top: 50px;

  }
}

 .core-values-icons img {
   @media (max-width: 500px) {
    width: 45px;
    height: 45px;

 }
}
.core-values-icons h3 {
  color: #fefefe;
  padding-top: 20px;
  
  @media (min-width: 640px) and (max-width: 1200px){
    height: fit-content;
  }
  
  
   @media (max-width: 500px) {
     padding-top: 0; 
   }
}
#french-language {
  .core-values-icons h3{
    @media (min-width: 1201px) and (max-width: 1647px) {
    height: 86px;
  }
  @media (min-width: 501px) and (max-width: 639px) {
    height: 68px;
  }
  }
}
.core-values-icons p {
  color: #d3d3d3;
  padding-top: 20px;
  width: 100%;
}
.core-values-icons{
    @media (max-width: 500px) {
        display: flex;
        gap: 20px;
        p{
            padding-top: 15px;
        }
     }
}
