.product-main-promo {
    width: 100%;
    padding: 150px 0 ;
    @media (max-width: 1024px) {
        padding: 70px 0 ;
    }
    @media (max-width: 480px) {
        padding: 50px 0 ;
    }
    background-image: url("../../assets/product-main-promo-img1.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-main-promo--content {
    max-width: 1280px;
    width: 90%;
    > * {
        text-align: center;
    }
}

.product-main-promo--title {
    color: #e5e5e5;
    // font-size: 60px;
    // @media (max-width: 1400px) {
    //     font-size: 50px;
    // }
    // @media (max-width: 1200px) {
    //     font-size: 40px;
    // }
    // @media (max-width: 1024px) {
    //     font-size: 30px;
    // }
    // @media (max-width: 767px) {
    //     font-size: 24px;
    // }
    letter-spacing: 2px;
}

.product-main-promo--text {
    color: #ddd;
    margin-top: 30px;
}