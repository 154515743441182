.footer {
  height: 77px;
  max-width: 100vw;
  background-color: #333;
  display: flex;
  justify-content: center;
  .footer--content {
    margin-top: 10px;
    display: flex;
    align-items: center;
    max-width: 1280px;
    width: 90%;
    > * {
      color: #fff;
    }
    padding: 0px;
    @media (max-width: 1024px) {
      height: 67px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
    }
    .active {
      color: #fff;
    }
  }
}

.footer--copyright {
  padding-right: 7%;
  font-size: 14px;
  font-weight: 300;
  width: 280px;
}
.footer--links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.footer--link {
  text-decoration: none;
  border: 0 !important;
  padding: 0 20px;
  border-right: 1px solid gray;
  font-size: 16px;
  @media (max-width: 530px) {
    padding: 0 10px;
    font-size: 14px;
  }
  font-weight: 300;
  color: #c9c9c9;
}
.footer--link:hover {
  color: #fff;
  transition: 0.6s ease-in-out;
}

.footer--link:last-child {
  border: 0;
}

.footer--link:first-child {
  padding-left: 0;
}
