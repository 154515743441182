.product-intro {
    margin-top: 5%;
}

.product-intro-content--container {
    display: flex;
    justify-content: center;
    background-color: #f2f4f5;
    width: 100%;
    padding-bottom: 10%;

}

.product-intro--content {
    max-width: 1280px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.product-intro--left {
    width: 50%;
    @media (max-width: 767px) {
        width: 90%;
        margin-top: 40px;
    }
}

.product-intro-top {
    display: flex;
    align-items: center;
}

.product-intro-top--title {
    color: #ff9f36;
}

.product-intro-top--line {
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}

.product-intro--title {
    margin-top: 10px;
    color: #222;
}

.product-intro--text {
    margin-top: 20px;
    //color: #777;
}

.product-intro-card {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    width: 40%;
    margin-top: -7%;
    box-shadow: 0px 6px 15px #dadee3;
    @media (max-width: 767px) {
        max-width: 450px;
        width: 90%;
    }

}

.product-intro-card--list {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-intro-card-list--title {
    color: #ff9f36;
    font-size: 70px;
    margin-top: 20px;
    @media (max-width: 1200px) {
        font-size: 60px;
    }
    @media (max-width: 1024px) {
        font-size: 50px;
    }
    @media (max-width: 767px) {
        font-size: 40px;
    }
    @media (max-width: 400px) {
        font-size: 30px;
    }
}

.product-intro-card-list--text {
    //color: #777;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.product-intro-card-list-title--unit {
    font-size: 18px;
}

