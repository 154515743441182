.product-promo {
    background-image: url("../../assets/product-main-promo-img1.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100%;
}

.product-promo--content {
    max-width: 1000px;
    width: 90%;
    margin: 150px 0 ;
    @media (max-width: 1024px) {
        margin: 70px 0 ;
    }
    @media (max-width: 480px) {
        margin: 50px 0 ;
    }
    display: flex;
    flex-direction: column;

}

.product-promo--text {
    color: #fff;
    text-align: center;
    font-weight: 400;

    // @media (max-width: 767px) {
    //     text-align: left;
    // }
}

.product-promo--btn {
    width: 150px;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #fff;
    
    background-color: transparent;
    font-size: 16px;
    color: #fff;
    margin-top: 40px;
    align-self: center;
    // @media (max-width: 767px) {
    //     align-self: start;
    // }
    cursor: pointer;
    letter-spacing: 2px;
}

.product-promo--btn:hover {
    background: linear-gradient(90deg, rgba(67,138,239,1) 0%,   rgba(4,67,158,1) 100%);
    color: #fff;
    transition-property: all;
    transition-duration: 0.8s;
    border: 0;
}