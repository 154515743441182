.contact-us {
  // margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  @media (max-width: 1024px) {
    margin-top: 70px;
  }
  @media (max-width: 480px) {
    margin-top: 50px;
  }
}

.contact-us--container {
  max-width: 1280px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.contact-us--left {
  width: 46%;
  max-width: 550px;
  @media (max-width: 900px) {
    width: 100%;
  }
}

.contact-us-left-top {
  display: flex;
  align-items: center;
}

.contact-us-left-top--title {
  color: #ff9f36;
}

.contact-us-left-top--line {
  width: 120px;
  height: 1px;
  background-color: #ff9f36;
  margin-left: 20px;
}

.contact-us-left--title {
  color: #222;
  margin-top: 10px;
}

.contact-us-left--text {
  color: #777;
  margin-top: 20px;
}

.contact-us-left-content-bottom {
  width: 100%;
}
.contact-us-left-info {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  @media (max-width: 420px) {
    padding: 10px;
  }
  box-shadow: 0px 6px 15px #dadee3;
  margin-top: 20px;
 // max-height: 130px;
  height: 100%;
}

.contact-us-left-info--img {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  @media (max-width: 420px) {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
}

.contact-us-left-info--title {
  color: #04439e;
}

.contact-us-left-info--text {
  color: #777;
  margin-top: 10px;
  @media (max-width: 420px) {
    font-size: 1.4rem !important;
  }
}

.contact-us--right {
  width: 46%;
  max-width: 550px;
  padding: 40px;

    box-shadow: 0px 6px 15px #dadee3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        width: 100%;
        margin-top: 40px;
        padding: 20px;
        height: 100%;
        min-height: 457px;
        max-height: 550px;
    }
    
}

.contact-us--form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-us-form--input {
  height: 47px !important;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  text-indent: 20px;
  max-width: 450px;
}

.contact-us-form-textarea {
  height: 100%;
  max-height: 238px !important;
  min-height: 140px !important;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  max-width: 450px;
  text-indent: 20px;
}

.contact-us-form-textarea::placeholder {
  padding-top: 20px;
}

.contact-us-form-btn--container {
  width: 100%;
  display: flex;
  max-width: 450px;
}

.contact-us-form-btn {
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 2px 7px #636262;
  background: rgb(67, 138, 239);
  background: linear-gradient(
    90deg,
    rgba(4, 67, 158, 1) 0%,
    rgba(67, 138, 239, 1) 100%
  );
  font-size: 16px;
  color: #ddd;
  align-self: flex-start;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.contact-us-form-btn:hover {
  background: linear-gradient(
    90deg,
    rgba(67, 138, 239, 1) 0%,
    rgba(4, 67, 158, 1) 100%
  );
  color: #fff;
  transition-property: all;
  transition-duration: 0.8s;
}

.contact-us--map {
  width: 100%;
  height: 500px;
  @media (max-width: 1200px) {
    height: 400px;
  }

  @media (max-width: 600px) {
    height: 300px;
  }
  margin-top: 5%;
  > * {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
