.about-home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 100px;
    @media (max-width: 1024px) {
        margin-top: 70px;
        margin-bottom: 80px;
    }
    @media (max-width: 480px) {
        margin-top: 50px;
        margin-bottom: 60px;
    }


}

.about-home-top--content, .about-home--bottom {
    max-width: 1280px;
    width: 90%;
}

.about-home--top {
    display: flex;
    align-items: center;
}

.about-home-top--title {
    color: #ff9f36;
}

.about-home-top--line {
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}

.about-home--title {
    margin-top: 10px;
}

.about-home-top--text {
    max-width: 1280px;
    width: 100%;
    margin-top: 20px;
    // font-size: 16px;
}

.about-home--bottom {
    display: flex;
    column-gap: 60px;
    margin-top: 30px;
    @media (max-width: 767px) {
        flex-direction: column;
        row-gap: 30px;
    }
}

.about-home--right, .about-home--left {
    width: 50%;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.about-home-left--icon {
    margin-right: 10px;
    > * {
        color: #ff9f36;
    }
    // font-size: 16px;
}

.about-home-left--text {
    margin-bottom: 10px;
    display: flex;
    // font-size: 16px;
}

.about-home--btn {
    width: 150px;
    height: 45px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 2px 7px #636262;
    background: rgb(67,138,239);
    background: linear-gradient(90deg, rgba(4,67,158,1) 0%,  rgba(67,138,239,1) 100%);
    font-size: 16px;
    color: #ddd;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.about-home--btn:hover {
    background: linear-gradient(90deg, rgba(67,138,239,1) 0%,   rgba(4,67,158,1) 100%);
    color: #fff;
    transition-property: all;
    transition-duration: 0.8s;
}

// .about-home-right--text {
//     font-size: 16px;
// }