.product-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 80px;
    @media (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 60px;
    }
    @media (max-width: 480px) {
        margin-top: 30px;
        margin-bottom: 50px;
    }

}

.product-home--content {
    max-width: 1280px;
    width: 90%;
}

.product-home--top {
    display: flex;
    align-items: center;
}
.product-home-top--title {
    color: #ff9f36;
}

.product-home-top--line{
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}

.product-home--title {
    color: #222;
    margin-top: 20px;
}

.product-home--text {
    max-width: 1280px;
    color: #777;
    margin-top: 20px;
    // font-size: 16px;
    // line-height: 1.2;
}

.product-cards {
    width: 90%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
    }
}

.product-card {
    max-width: 400px;
    width: 32%;
    box-shadow: 0px 6px 15px #dadee3;
    @media (max-width: 767px) {
        max-width: 450px;
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-bottom: 20px;
}

.product-card--content {
    padding: 20px;
    height: 100%;
}

.product-card--btn {
    width: 150px;
    padding: 13px 0 ;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 2px 7px #636262;
    background: rgb(67,138,239);
    background: linear-gradient(90deg, rgba(4,67,158,1) 0%,  rgba(67,138,239,1) 100%);
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    margin-left: 20px;
    cursor: pointer;
    letter-spacing: 2px;
  }

  .product-card--btn:hover {
    background: linear-gradient(90deg, rgba(67,138,239,1) 0%,   rgba(4,67,158,1) 100%);
    color: #fff;
    transition-property: all;
    transition-duration: 0.8s;
  }

  .product-card--img {
    width: 100%;
    object-fit: cover;
  }

  .product-card--title {
    color: #222;
  }
  .product-card--text {
    margin-top: 20px;
    font-size: 16px;
  } 

  

