.about-us-container {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 7%;
  margin-bottom: 7%;
  gap: 50px;
  @media (min-width: 1024px) and (max-width: 1300px) {
    width: 90%;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 90%;
  }
}

.about-us-content {
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.about-us-content h2 {
  padding-bottom: 20px;
}
.about-us-content p {
  color: #707070;
}
.about-highlights {
  padding-top: 30px;
}
.highlight-list {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  padding-left: 45px;
  @media(max-width: 500px){
    padding-left: 10px;
  }
}

.section-intro-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 10px;
}
.section-intro {
  font-size: 14px;
  color: #f77b00;
  margin: 0;
}

.section-intro-line {
  width: 100px;
  height: 1px;
  border: none;
  background-color: #f77b00;
  opacity: 1;
}
.about-us-img {
  display: flex;
  // align-items: center;
  width: 50%;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 100%;
    // height: 400px;
  }
}

.about-us-img img {
  width: 100%;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 80%;
    height: 80%;
  }

  object-fit: cover;
  object-position: center;
}
@media (min-width: 1201px) {
  #french-language {
    .about-content-wrapper {
      width: 100%;
      display: flex;
    }
    .about-intro-wrapper {
      width: 50%;
    }
    .about-highlights {
      width: 50%;
    }

    .about-us-content {
      width: 100%;
    }
    .about-us-container {
      display: flex;
      flex-direction: column;
    }
    .about-us-img{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .about-us-img img{
        width: 100%;
        max-width: 1100px;
        height: 400px;
        
    }
  }
}
