.navbar {
  width: 100%;
  height: 77px;

  @media (max-width: 1024px) {
    height: 67px;
  }

  @media (max-width: 600px) {
    height: 56px;
  }

  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 15px #3a3838;
  position: relative;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 4;
}

.navbar--col3 {
  width: 20%;
  background-color: #04439e;
}

.navbar--logo {
  height: 100%;
  background-color: #fff;
  transform: skew(-45deg);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  @media (max-width: 1400px) {
    width: 25%;
  }
  @media (max-width: 1200px) {
    width: 20%;
  }
  @media (max-width: 1024px) {
    width: 40%;
    justify-content: flex-start;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
}

.navbar-logo--img {
  width: 124px;
  height: 50px;
  transform: skew(45deg);
  object-fit: cover;
  @media (max-width: 1024px) {
    width: 102px;
    height: 41px;
    padding: 0;
  }

  @media (max-width: 600px) {
    width: 80px;
    height: 32px;
  }
  cursor: pointer;

}

.navbar-item--list {
  width: 70%;
  height: 100%;
  background-color: #04439e;
  transform: skew(-45deg);
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1400px) {
    width: 75%;
  }
  @media (max-width: 1200px ) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
}

.navbar--items {
  width: 100%;
  height: 100%;
  transform: skew(45deg);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-page--links {
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
}

.navbar--item {
  font-size: 16px;
  color: #fff;
  padding: 0px 30px;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 1024px) {
    display: none;
  }
  cursor: pointer;
}

.navbar--item:hover {
  > * {
    color: #ff9f36;
  }

  @media (min-width: 1025px) {
    border-top: 2px solid #ff9f36;
  }
}

.active {
  > * {
    color: #ff9f36;
  }

  @media (min-width: 1025px) {
    border-top: 2px solid #ff9f36;
  }
}

.navbar--lang {
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid gray;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  @media (max-width: 1024px) {
    border: 0;
    margin: 0;
    padding: 0;
    border-right: 1px solid gray;
    padding-right: 30px;
    margin-right: 30px;
    @media (max-width: 600px) {
      padding-right: 10px;
      margin-right: 10px;
    }
  }
}
.navbar--lang:hover{
  color: #ff9f36;
}
.navbar-toggle--btn {
  @media (max-width: 1024px) {
    display: flex;
    position: relative;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    .bar {
      position: absolute;
      width: 22px;
      border-radius: 2px;
      height: 1px;
      background-color: #fff;
      transition: all 0.5s ease-in-out;
      &::before, &::after {
        content: "";
        position: absolute;
        width: 22px;
        border-radius: 2px;
        height: 1px;
        background-color: #fff;
        transition: all 0.5s ease-in-out;
      }

      &::before {
        width: 22px;
        transform: translateY(-7px);
      }

      &::after {
        width: 22px;
        transform: translateY(7px);
      }

    }
  }
}

.navbar--col1 {
  width: 50vw;
  height: 100%;
  background-color: #fff;
}

.navbar--col2 {
  width: 50vw;
  height: 100%;
  background-color: #04439e;
}

.navbar--container {
  max-width: 1278px;
  width: 90%;
  height: 77px;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;

  // @media (max-width: 1200px) {
  //   width: 80%;
  // }

  @media (max-width: 1024px) {
    // width: 85%;
    height: 67px;
  }

  @media (max-width: 600px) {
    width: 90%;
    height: 56px;
  }
}

.navbar--mobile {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  padding-top: 3%;
  background-color: #282828;
  .navbar--col1, .navbar--col2,.navbar--logo, .navbar--lang {
    display: none;
  }
  .navbar--container {
    height: auto;
  }


  .navbar--items, .navbar-item--list {
    background-color: #282828;
    transform: skew(0deg);
    width: 100%;
    height: 100%;
  }
  

  .navbar--items {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 11px;
  }

  .navbar-page--links {
    width: 100%;
  }
    
  .navbar--item {
    align-self: flex-start;
    display: flex;
    align-items: center;
    color: #fff;
    border-bottom: 1px solid #3b3f41;
    height: 66px;
    width: 100%;
    padding: 0;
    font-size: 25px;
    
    @media (max-width: 600px) {
      font-size: 20px;
      height: 57px;
    }
  }

  .navbar-toggle--btn {
    align-self: flex-end;
    margin-bottom: 12%;
    .bar {
      width: 0;
      transform: translateX(-11px);
      &::before {
        transform: rotate(45deg) translate(-2px, 0px);
      }
    
      &::after {
        transform: rotate(-45deg) translate(0px, -2px);
      }
    }
  }

  
}

// .navbar--mobile.navbar--active {
//   right: 0;
//   transition: right 0.5s ease-in-out;
// }