.product-feature {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 100px;
    @media (max-width: 1024px) {
        margin-top: 70px;
        margin-bottom: 80px;
    }
    @media (max-width: 480px) {
        margin-top: 50px;
        margin-bottom: 60px;
    }

}

.product-feature-top-content {
    max-width: 1280px;
    width: 90%;
}

.product-feature-top {
    display: flex;
    align-items: center;
}

.product-feature-top--title1 {
    color: #ff9f36;
}

.product-feature-top--line {
    width: 120px;
    height: 1px;
    background-color: #ff9f36;
    margin-left: 20px;
}

.product-feature-top--title2 {
    margin-top: 10px;
    color: #222;
}

.product-feature-top--text {
    //color: #777;
    margin-top: 20px;
}

.product-feature-icons {
    display: flex;
    column-gap: 1px;
    max-width: 1280px;
    margin-top: 4%;
    width: 90%;
    justify-content: space-between;
    @media (max-width: 650px) {
        flex-direction: column;
    }
}
.product-feature-list--row1, .product-feature-list--row2 {
    display: flex;
    column-gap: 1px;
    width: 50%;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
   
    @media (max-width: 650px) {
        width: 100%;
    }
}

.product-feature--list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;

    @media (max-width: 1024px) {
        flex-direction: row;
        width: 100%;
        justify-content: start;
        align-items: center;
    }
}

.product-feature-list--icon {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 1024px) {
        flex-direction: column;
        width: auto;
        justify-content: center;
        align-items: center;
    }
}

.product-feature-list--icon--img {
    width: 90px;
    height: 90px;
    @media (max-width:1200px) {
        width: 70px;
        height: 70px;
    }
    @media (max-width:1024px) {
        width: 50px;
        height: 50px;
    }
    object-fit: contain;
    box-shadow: 0px 6px 25px #dadee3;
    border-radius: 70px;
    border: 7px solid #e5e5e5;
}

.product-feature-list--icon--img:hover {
    transform: scale(1.2);
}

.product-feature-icon--line {
    min-width: 2px;
    height: 5px;
    background-color: #e5e5e5;
    width: 100%;
    margin-left: -1px;
    @media (max-width: 1024px) {
        height: 10px; 
        width: 5px;
        margin: 0;
    }
}


.product-feature-list--title {
    font-size: 20px;
    color: #04439e;
    text-align: center;
    margin-top: 20px;
    @media (max-width: 1024px) {
        margin: 0;
        margin-left: 10px;
        text-align: left;
    }
}

.product-feature-icon-line-last, .product-feature-icon-line-first {
    background-color: #fff !important;
    @media (max-width: 1024px) {
        display: none;
    }
}   



.product-feature-icon-line-col {
    @media (max-width: 1024px) {
        display: none;
    }
   
    @media (max-width: 650px) {
        display: block;
    }
}


#french-language{

    .product-feature-icon-line-col {
        display: none;
        @media (max-width: 650px) {
            display: block;
        }
    }  

    .product-feature-icon-line-last, .product-feature-icon-line-first {
        background-color: #fff !important;
        // @media (max-width: 1024px) {
        //     display: none;
        // }
        display: none;
    }

    .product-feature-list--title {
        font-size: 20px;
        color: #04439e;
        // text-align: center;
        // margin-top: 20px;
        // @media (max-width: 1024px) {
        //     margin: 0;
        //     margin-left: 10px;
        //     text-align: left;
        // }
        margin: 0;
            margin-left: 10px;
            text-align: left;
    }

    .product-feature-icon--line {
        min-width: 2px;
        // height: 5px;
        background-color: #e5e5e5;
        // width: 100%;
        // margin-left: -1px;
        // @media (max-width: 1024px) {
        //     height: 10px; 
        //     width: 5px;
        //     margin: 0;
        // }
        height: 10px; 
        width: 5px;
        margin: 0; 
    }

    .product-feature-list--icon {
        // display: flex;
        // align-items: center;
        // width: 100%;
        // @media (max-width: 1024px) {
        //     flex-direction: column;
        //     width: auto;
        //     justify-content: center;
        //     align-items: center;
        // }
        display: flex;
        flex-direction: column;
            width: auto;
            justify-content: center;
            align-items: center;
    }

    .product-feature--list {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // width: 25%;
    
        // @media (max-width: 1024px) {
        //     flex-direction: row;
        //     width: 100%;
        //     justify-content: start;
        //     align-items: center;
        // }
    
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }

    .product-feature-list--row1, .product-feature-list--row2 {
        // display: flex;
        // column-gap: 1px;
        // width: 50%;
        // @media (max-width: 1024px) {
        //     flex-direction: column;
        // }
        width: 45%;
        display: flex;
        flex-direction: column;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
}